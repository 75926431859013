<template>
  <div>
    <div class="wet-section">
      <div class="container">
        <p style="font-size: max(min(3.2vw, 16px), 13px);">
          {{str.buyVacation.known_from}}
        </p>
        <hr />
      </div>
      <div class="container">
        <main-section
          class="mb-3"
          :lang=getLang
          :main-title-start="offer_title_start"
          :main-title-end="str.buyVacation.offer_title_end"
          :sub-title="str.buyVacation.offer_subtitle"
          :period-title="str.buyVacation.period_title"
          :period-text="str.buyVacation.period_tpl"
          :location-title="str.buyVacation.location_title"
          :severity-title="str.buyVacation.product_title"
          :severity-text="str.buyVacation.product_tpl"
          :weather-context-derived="weather_context_derive"
          :max-payout-text="str.buyVacation.max_payout_text"
          :non-insured-days-title="str.buyVacation.non_insured_days_title"
          :non-insured-days-text="str.buyVacation.non_insured_days_text"
          :price-title="str.buyVacation.price_title"
          :address="orderData.location.address"
          :currency="orderData.currency"
          :initial-daily-payout="orderData.daily_payout"
          :possible-daily-payout-values="possibleDailyPayoutValues"
          :end-date="orderData.endDate"
          :start-date="orderData.startDate"
          :start-date-num="orderData.startDateNum"
          :end-date-num="orderData.endDateNum"
          :latitude="orderData.location.latitude"
          :longitude="orderData.location.longitude"
          :hotel-name="orderData.location.name"
          :street-and-number="getStreetAndNumber()"
          :town="orderData.location.city"
          :country="orderData.location.country"
          :partner-code="partnerCode"
          :zip-code="orderData.location.zip"
          :min-hour="orderData.minHour"
          :max-hour="orderData.maxHour"
          :threshold="orderData.weather.threshold"
          :risk-days="orderData.risk_days"
          :max-payout="orderData.reduction"
          :price="orderData.price"
          :rainy_day_str="rainy_day"
          :rainy_days_str="rainy_days"
          :are_str="str.buyVacation.are"
          :is_str="str.buyVacation.is"
          :today="str.buyVacation.today"
          :tomorrow="str.buyVacation.tomorrow"
          :urgency_to_buy_text="str.buyVacation.urgency_to_buy_text"
          :until="str.buyVacation.until"
          :min_postpone="min_postpone"
          :rain-amount-header="str.buyVacation.rain_amount_header"
          :rain-amount-info="str.buyVacation.rain_amount_info"
          :share-text="str.buyVacation.share_quote_text"
          :whatsapp-share-text="str.buyVacation.share_quote_whatsapp_text"
          :email-share-text="str.buyVacation.share_quote_email_text"
          :email-subject-text="str.buyVacation.share_quote_email_subject"
          :copy-to-clipboard-text="str.buyVacation.share_quote_copy_to_clipboard_text"
          @update-daily-payout="updateDailyPayout"
          @threshold-rollout-opened="openedThresholdRollout = true"
        />
        <payout-table
          :header_number_of_days="str.done.number_of"
          :your_payout_text="str.done.your_payout_text"
          :rainy_day_str="rainy_day"
          :rainy_days_str="rainy_days"
          :api_data="orderData"
          :weather_direction="orderData.weather.direction"
          :contract_days="noDays"
          :daily_payout="orderData.daily_payout"
          :currency="orderData.currency"
          :risk_days="orderData.risk_days"
          :historical_payout_template_text="str.done.historical_payout_template_text"
        />
        <hr/>
        <vacation-summary
          :initial-daily-payout="orderData.daily_payout"
          :possible-daily-payout-values="possibleDailyPayoutValues"
          :currency="orderData.currency"
          :summary-end="str.buyVacation.summary_end"
          :summary-start="summary_start"
          :summary-title="str.buyVacation.summary_title"
          :max-payout="orderData.reduction"
          :price="orderData.price"
          :show-price-title="showPrice"
          :price-title="str.buyVacation.price_title"
          :lang="getLang"
          @update-daily-payout="updateDailyPayout"
          />
        <div id="personal-info-container" v-if="personalInfoVisible">
          <hr/>
          <name-and-phone
            :error-first-name="str.commonApp.error_firstname"
            :error-last-name="str.commonApp.error_lastname"
            :first-name-place-holder="str.buyVacation.first_name_plh"
            :last-name-place-holder="str.buyVacation.last_name_plh"
            :phone-place-holder="str.buyVacation.phone_plh"
            :first-name-initial="profileData.firstname"
            :last-name-initial="profileData.lastname"
            :phone-initial="profileData.phone"
            :phone-prefix="profileData.phone_code"
            :personal-data-title="str.buyVacation.persnl_i_title"
            :phone-error="str.commonApp.error_phone"
            @change-firstname="updateFirstname"
            @change-lastname="updateLastname"
            @change-phone="updatePhone"
            @change-phone-code="updatePhoneCode"
            @mounted="scrollToNameAndPhone"
            ref="nameAndPhone"
          />
          <h3 class="empty-ph is-size-6">
            {{ str.additional.addr_private_title }}
          </h3>
          <places-text
            ref="registrationAddress"
            @change-address="updateRegistrationAddress"
            @change-invalid-value="updateRegistrationInvalid"
            @change-invalid-country="updateRegistrationInvalidCountry"
            @change-invalid-street-value="updateRegistrationInvalidStreet"
            @change-house-no-value="updateHouseNo"
            @change-zip-value="updateZip"
            @change-city-value="updateCity"
            @change-country-value="updateCountry"
            @change-country-code-value="updateCountryCode"
            :selectedAddress="registration"
            :bounds="boundsEurope"
            class="my-3"
            :fulladdress="true"
            :searchTypes="['address']"
            :searchCountries="searchCountries"
            :address-error-text="str.additional.error_registraion_address"
            :address-placeholder="str.additional.str_and_house_plh"
            :street-placeholder="str.additional.str_plh"
            :house-no-error-text="str.additional.error_house_no"
            :city-error-text="str.additional.error_city"
            :zip-error-text="str.additional.error_zip"
            :zip-placeholder="str.additional.zip_code_plh"
            :city-placeholder="str.additional.city_plh"
            :country-placeholder="str.additional.country_plh"
            :country-error-text="str.buyVacation.error_registration_wrong_country_short"
            :house-no-placeholder="str.additional.house_no_plh"
          ></places-text>
          <div
            v-if="agencySettingId > 0"
            class="mb-3 "
          >
            <br/>
            <h3 class="empty-ph is-size-6">
              {{agency_title}}
            </h3>
            <p class="empty-ph">
              {{agency_text}}
            </p>
            <div
              class="wet-input wet-input-with-label"
              :class="$v.agency_id.$error ? 'wet-input-error' : ''"
            >
              <input
                id="wet-input-agency_id"
                type="text"
                :placeholder="agency_plh"
                v-model.trim="$v.agency_id.$model"
              >
            </div>
            <div v-if="$v.agency_id.$error" class="small text-primary mt-n2 mb-3 mx-3">
              {{ error_agency }}
            </div>
          </div>
          <hr>
          <!-- Documents download -->
          <h2 class="empty-ph text-dark">
            {{ str.buyVacation.documents_download }}
          </h2>
          <div class="empty-ph wet-note ">
            {{ str.buyVacation.documents_download_description }}
          </div>
          <div
            v-for="(link, i) in orderData.documents.files"
            :key="i"
            class="text-secondary font-weight-bold my-3"
          >
            <a
              :id="`buy-vacation-document-link-${i + 1}`"
              :title="link.filename"
              :href="link.url"
              target="_blank"
            >{{ link.name }}</a>
          </div>
          <a
            :id="`wet-buy-download-all`"
            :title="str.buyVacation.download_all"
            :href="this.orderData.documents.all.url"
            target="_blank"
            download
          >
            <button
              class="wet-button wet-button-outlined-primary empty-ph"
              style="min-width:10rem;"
              @click="downloadedData = true"
            ><slot>{{ str.buyVacation.download_all }}</slot></button>
          </a>
          <div v-if="$v.checkboxDownload.$error && checkboxDownloadDisabled"
               class="small text-primary mt-3 mb-3 mx-3">
            {{ str.buyVacation.error_download }}
          </div>
          <hr>
          <register-section
            v-if="showRegistrationForm"
            :email-taken="emailTaken"
            :password-retype-error="str.buyVacation.error_password_retype"
            :password-retype-place-holder="str.buyVacation.retype_pass_plh"
            :error-password="str.buyVacation.error_password"
            :password-place-holder="str.buyVacation.pass_plh"
            :email-already-used-error="str.commonApp.error_email_already_used"
            :email-error="str.buyVacation.error_email"
            :email-place-holder="str.buyVacation.email_plh"
            :register-title="str.buyVacation.create_acc_title"
            @change-email="updateEmail"
            @change-password="updatePassword"
            ref="RegisterSection"
          ></register-section>
          <label
            id="wet-buy-vacation-chkbx1-label"
            class="checkbox is-size-7 my-3"
            :class="{
            'disabled': checkboxDownloadDisabled,
          }"
            :title="checkboxDownloadDisabled ? 'Please, press &quot;Download&quot; all button' : ''"
            for="wet-buy-vacation-chkbx1"
          >
            <strong class="empty-ph">
              {{ checkbox_download }}
            </strong>
            <input
              type="checkbox"
              id="wet-buy-vacation-chkbx1"
              class=""
              v-model="$v.checkboxDownload.$model"
              :disabled="checkboxDownloadDisabled"
            >
            <span id="wet-buy-chkbx1-span"></span>
          </label>
          <div v-if="$v.checkboxDownload.$error  && !checkboxDownloadDisabled"
               class="small text-primary mt-n2 mb-3 mx-3">
            {{ str.buyVacation.error_accept }}
          </div>
        </div>
      </div>
    </div>
    <wetModal
      id="wet-modal-additional-sepa"
      :opened="modalsOpened.sepa"
      @close="$set(modalsOpened, 'sepa', false)"
      :content="`<div class='wet-note text-center'>
      ${insurerId && str.buyVacation.sepa_info && str.buyVacation.sepa_info[insurerId]}
      </div>`"
      :actions="[
        {
          title: str.commonApp && str.commonApp.ok,
          name: 'ok-additional-ok',
          event: () => $set(modalsOpened, 'sepa', false)
        }
      ]"
    >
    </wetModal>
  </div>
</template>
<script>
import LogService from '@/services/LogService';
import MergeHelper from '@/helpers/MergeHelper';
import common from '@/mixins/common';
import places from '@/mixins/places';
import payoutTableMixin from '@/mixins/payoutTableMixin';
import { nanoid } from 'nanoid';
import { errorStrings } from '@/constants';
import wpSection from '@/enums/wpSection';
import placesText from '@/components/placesTextComponent.vue';
import weatherParameterContext from '@/mixins/weatherParameterContext';
import weatherSeverity from '@/enums/WeatherSeverity';
import vacationSummary from '@/components/vacation-buy/vacationSummary.vue';
import {
  additional,
  buyVacation,
  commonApp,
  doneStrings,
} from '@/enums/stringIndices';
import { agency_id_1_to_6, agency_id_1_to_20 } from '@/common/validations';
import agencySettingIds from '@/enums/agencySettingIds';
import { eventNames, currencyAbbreviation, productNames } from '@/enums/googleTagManager';
import mapAddresses from '@/mixins/mapAddresses';
import payoutTable from '@/components/done/PayoutTable.vue';
import PaymentTypeId from '@/enums/PaymentTypeId';
import setLayout from '@/mixins/setLayout';

export default {
  mixins: [common, places, weatherParameterContext, mapAddresses, payoutTableMixin, setLayout],
  components: {
    payoutTable,
    placesText,
    vacationSummary,
    registerSection: () => import('@/components/vacation-buy/RegisterSection.vue'),
    mainSection: () => import('@/components/vacation-buy/mainSection.vue'),
    nameAndPhone: () => import('@/components/vacation-buy/nameAndPhone.vue'),
  },
  data() {
    return {
      scrollToNameAndPhoneFlag: true,
      personalInfoVisible: false,
      progress: [2, 3],
      downloadedData: false,
      openedThresholdRollout: false,
      currency: '',
      loadingId: 1,
      thresholdUnit: 'mm',
      partnerName: '',
      partnerCode: 'wetterheld',
      promotionId: '',
      agency_id: '',
      showPass: false,
      showPassRetype: false,
      emailTaken: false,
      checkboxDownloadDisabled: false,
      checkboxDownload: false,
      racceptChkbx: false,
      registrationInvalid: false,
      registrationInvalidStreet: false,
      registrationInvalidCountry: false,
      agencySettingId: agencySettingIds.no_agency_id,
      searchCountries: ['DE'],
      min_postpone: 15,
      orderData: {
        business: {
          type: '',
        },
        payment_type_id: 2,
        contract_days: 0,
        price: 0,
        reduction: 0,
        daily_payout: 0,
        risk_days: 0,
        dates: [],
        hours: [],
        startDate: '',
        startDateNum: '2099-01-01',
        endDate: '',
        minHour: 0,
        maxHour: 0,
        location: {
          address: '',
          latitude: 0,
          longitude: 0,
          city: '',
          country_code: '',
          country: '',
          place_id: '',
          name: '',
        },
        documents: {
          files: [],
          all: {
            url: '',
          },
        },
        currency: '',
        weather: {
          type: 'rain',
          direction: 'rainy',
          threshold: 9999,
        },
      },
      userData: {
        status: 5,
      },
      profileData: {
        firstname: '',
        lastname: '',
        phone: '',
        phone_code: 49,
        registration_address: {
          address: undefined,
          address_line_1: undefined,
          address_line_2: undefined,
          address_line_city: undefined,
          address_line_zip: undefined,
          country: undefined,
          country_code: undefined,
        },
      },
      initialAddress: {
        address: undefined,
        address_line_1: undefined,
        address_line_2: undefined,
        address_line_city: undefined,
        address_line_zip: undefined,
        country: undefined,
        country_code: undefined,
      },
      firstname: '',
      lastname: '',
      phone: '',
      phoneCode: '49',
      email: '',
      password: '',
      registration: {},
      modalsOpened: {
        sepa: false,
      },
      insurerId: -1,
      agentId: -1,
      str: {
        buyVacation,
        commonApp,
        additional,
        done: doneStrings,
      },
      agency_title: '',
      agency_text: '',
      agency_plh: '',
      error_agency: '',
      agencyIdValidation: agency_id_1_to_6,
    };
  },
  computed: {
    checkbox_download() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return this.str.buyVacation.checkbox_download_full_coupon;
      }
      return this.str.buyVacation.checkbox_download;
    },
    offer_title_start() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return this.str.buyVacation.offer_title_start_coupon;
      }
      return this.str.buyVacation.offer_title_start;
    },
    summary_start() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return this.str.buyVacation.summary_start_full_coupon;
      }
      return this.str.buyVacation.summary_start;
    },
    setConstantDailyPayout() {
      return this.orderData.payment_type_id === PaymentTypeId.fullCoupon;
    },
    severalDaysPossibleDailyPayoutValues() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return [50];
      }
      return [50, 100, 150, 200, 250, 300];
    },
    possibleDailyPayoutValues() {
      return this.onlyOneDay && this.germanInsuranceLocation
        ? this.oneDayPossibleDailyPayoutValues
        : this.severalDaysPossibleDailyPayoutValues;
    },
    oneDayPossibleDailyPayoutValues() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return [50];
      }
      return [50, 100, 150, 200, 250, 300, 350, 400, 500, 600, 700,
        800, 900, 1000];
    },
    showPrice() {
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        return false;
      }
      return true;
    },
    onlyOneDay() {
      return this.noDays === 1;
    },
    germanInsuranceLocation() {
      return this.orderData.location?.country_code === 'DE';
    },
    weather_context_derive() {
      const value = this.weather_parameter_context_example(
        this.orderData.weather.type,
        this.orderData.weather.threshold,
        this.str.done.severity_description,
      );
      LogService.log('BuyVacation, weather_context_derive', value);
      return value;
    },
    boundsEurope() {
      // europe
      const { maps } = this.googleApis;
      LogService.log('maps', maps);
      if (!maps) return undefined;
      const southWest = new maps.LatLng(32.880155, -14.177284);
      const northEast = new maps.LatLng(71.594625, 45.852008);
      return new maps.LatLngBounds(southWest, northEast);
    },
    errorRegistrationWrongCountryFormatted() {
      const regionNames = new Intl.DisplayNames([this.lang], { type: 'region' });
      const residenceCountries = this.searchCountries.map((x) => regionNames.of(x))
        .sort().join(', ');
      return this.format(
        this.str.buyVacation.error_registration_wrong_country,
        this.registration.country,
        residenceCountries,
      );
    },
    showRegistrationForm() {
      LogService.log('should show registration form? userData.status', this.userData.status);
      return [3, 5].includes(this.userData.status);
    },
    getLang() {
      return this.lang;
    },
    stringsf() {
      if (!this.showPrice) {
        return {
          nextButton: this.str.buyVacation.next_btn_tpl_no_price,
        };
      }

      const nextButton = this.format(
        this.personalInfoVisible
          ? this.str.buyVacation.next_btn_tpl : this.str.buyVacation.next_to_personal_data,
        new Intl.NumberFormat(this.lang, { minimumFractionDigits: 2 })
          .format(this.orderData.price),
        this.orderData?.currency,
      );
      return {
        nextButton,
      };
    },
  },

  watch: {
    openedThresholdRollout(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.$webAppAPI.postUpdateVacationField(eventNames.thresholdRollout);
        window.dataLayer.push({ event: eventNames.thresholdRollout });
      }
    },
    downloadedData(newValue, oldValue) {
      LogService.log('downloadedData', newValue, oldValue);
      if (oldValue === false && newValue === true) {
        this.$webAppAPI.postUpdateVacationField(eventNames.documentsDownloaded);
        window.dataLayer.push({ event: eventNames.documentsDownloaded });
      }
    },
    'stringsf.nextButton': {
      immediate: true,
      handler(title) {
        LogService.log('watch string nextButton', title);
        // set next button title
        this.$emit('update:nextTitle', title);
      },
    },
  },
  created() {
    LogService.log('creating screen, loadingId', this.loadingId);
    this.$store.commit('SET_LOADING', this.loadingId);
    // next button enable
    this.$emit('update:nextDisabled', true);

    // next & back buttons click watcher
    this.$root.$on('buttonNext', this.next);
    this.$root.$on('buttonBack', this.back);

    // set page progress
    this.$store.commit('SET_APP_LAYOUT', { progress: this.progress });

    this.init();
  },
  validations() {
    return {
      checkboxDownload: { checked: (val) => val },
      agency_id: this.agencyIdValidation,
    };
  },
  methods: {
    updateOpenedPersonalData() {
      this.$webAppAPI.postUpdateVacationField(eventNames.openedPersonalData);
      window.dataLayer.push({ event: eventNames.openedPersonalData });
    },
    async updateDailyPayout(newValue) {
      const oldValue = this.orderData.daily_payout;
      const loadingId = nanoid();
      LogService.log('creating screen, loadingId', loadingId);
      await this.$store.commit('SET_LOADING', loadingId);
      LogService.log('buyVacation updateDailyPayout', newValue);
      this.orderData.daily_payout = newValue;
      this.orderData.reduction = (this.orderData.dates.length - this.orderData.risk_days)
        * this.orderData.daily_payout;
      await this.callOrderPriceApi().catch(() => {
        this.orderData.daily_payout = oldValue;
        this.orderData.reduction = (this.orderData.dates.length - this.orderData.risk_days)
          * this.orderData.daily_payout;
        this.showPriceCalcErrorModal();
      });

      await this.$store.commit('DEL_LOADING', loadingId);
    },
    deriveFormattedAddress() {
      const r = this.registration;
      return `${r.street} ${r.house}, ${r.zip} ${r.city}, ${r.country}`;
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    scrollToNameAndPhone() {
      if (this.scrollToNameAndPhoneFlag) {
        window.scrollTo({
          top: this.$refs.nameAndPhone.$el.offsetTop - 120,
          behavior: 'smooth', // You can use 'auto' or 'smooth' for smooth scrolling
        });
      }
    },
    updateFirstname(firstname) {
      this.firstname = firstname;
      LogService.log('changed firstname', firstname);
    },
    updateRegistrationAddress(registrationAddress) {
      this.registration = registrationAddress;
      LogService.log('vacation buy bounds', this.boundsEurope);
      LogService.log('changed registration address', registrationAddress);
    },
    updateHouseNo(houseNo) {
      this.registration.house = houseNo;
      LogService.log('changed houseNo', houseNo);
    },
    updateZip(zip) {
      this.registration.zip = zip;
      LogService.log('changed zip', zip);
    },
    updateCity(city) {
      this.registration.city = city;
      LogService.log('changed city', city);
    },
    updateCountry(country) {
      this.registration.country = country;
      LogService.log('changed country', country);
    },
    updateCountryCode(countryCode) {
      this.registration.country_code = countryCode;
      LogService.log('changed country_code', countryCode);
    },
    updateLastname(lastname) {
      this.lastname = lastname;
      LogService.log('changed lastname', lastname);
    },
    updatePhone(phone) {
      this.phone = phone;
      LogService.log('changed phone', phone);
    },
    updatePhoneCode(phoneCode) {
      this.phoneCode = phoneCode;
      LogService.log('changed phoneCode', phoneCode);
    },
    updateEmail(email) {
      this.email = email;
      this.emailTaken = false;
      LogService.log('changed email', email);
    },
    updatePassword(password) {
      this.password = password;
      LogService.log('changed password', password);
    },
    updateRegistrationInvalid(newValue) {
      LogService.log('registrationInvalidUpdate to value', newValue);
      this.registrationInvalid = newValue;
    },
    updateRegistrationInvalidStreet(newValue) {
      LogService.log('registrationInvalidStreetUpdate to value', newValue);
      this.registrationInvalidStreet = newValue;
    },
    updateRegistrationInvalidCountry(newValue) {
      LogService.log('registrationInvalidUpdate to value', newValue);
      this.registrationInvalidCountry = newValue;
      if (this.registrationInvalidCountry) {
        this.showWrongCountryModal();
      }
    },
    async updateUser() {
      // call api update user
      const data = {
        profile: {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          phone_code: this.phoneCode,
          agent_id: this.agentId,
        },
        registration_address: {
          address_line_1: this.registration.street,
          address_line_2: this.registration.house,
          address_line_zip: this.registration.zip,
          address_line_city: this.registration.city,
          country: this.registration.country,
          country_code: this.registration.country_code,
        },
      };

      if (!this.registrationInvalid && this.registration.place_id !== undefined) {
        data.registration_address.place_id = this.registration.place_id;
        data.registration_address.address = this.registration.address;
      } else {
        data.registration_address.address = this.deriveFormattedAddress();
      }

      LogService.log('updateUser, check if user is registered');
      if (this.showRegistrationForm) {
        LogService.log('updateUser, need to add user data');
        data.user = {
          email: this.email,
          password: this.password,
        };
      }

      LogService.log('updateUser, check if tui agency needs to be added');
      if (this.agencySettingId > 0 && this.agency_id) {
        LogService.log('updateUser, need to add agency id');
        data.order = {
          agency_id: this.agency_id,
        };
      }

      LogService.log('updateUser, start API call');
      await this.$webAppAPI.putUserProfilePayment(data);
    },
    async createStripeSession() {
      const result = await this.$webAppAPI.postCreateStripeSession(this.inIframe());
      LogService.log('Response create stripe session', result, result.data);
      return result.data;
    },
    async init() {
      LogService.log('in iframe', this.inIframe());
      LogService.log('init get Wordpress Strings and User data');
      await Promise.all([
        await this.getWordPressStrings(
          [
            wpSection.BUY_VACATION,
            wpSection.ADDITIONAL,
            wpSection.COMMON_APP,
            wpSection.DONE,
          ],
          wpSection.BUY_VACATION,
        ).catch((err) => this.redirectToVacationErrorHandler(err)),
        await this.callGetUserInfoApi().catch((err) => this.redirectToVacationErrorHandler(err)),
      ]);
      await this.updateRainyDaysStrings(this.orderData.weather.direction, this.orderData.dates);
      LogService.log('init call optimal contract api');
      await this.callOptimalContractAPI();
      LogService.log('init call price api');
      await this.callOrderPriceApiInitial();
      this.$store.commit('RESET_LOADING');
      LogService.log('init enable next button');
      this.$emit('update:nextDisabled', false);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: eventNames.viewItem,
        ecommerce: {
          currency: currencyAbbreviation.euro,
          value: this.orderData.price,
          items: [
            {
              item_name: productNames.vacation,
              affiliation: this.partnerName,
              price: this.orderData.price,
              location_id: this.orderData.location.place_id,
              item_category: this.orderData.dates[0],
              item_category2: this.orderData.dates.at(-1),
              item_category3: this.orderData.dates.length,
              item_category4: this.orderData.risk_days,
              item_category5: this.orderData.daily_payout,
              item_variant: this.orderData.location.address,
              promotion_id: this.promotionId,
            },
          ],
        },
      });
    },
    async callOrderPriceApi() {
      const res = await this.$webAppAPI.postPrice(
        this.orderData.daily_payout,
        this.orderData.risk_days,
        this.orderData.weather.severity,
        this.orderData.weather.severity === weatherSeverity.CUSTOM
          ? this.orderData.weather.threshold : null,
      );
      this.orderData.weather.threshold = res.data.threshold;
      this.orderData.price = res.data.insurers?.[0]?.price?.[0]?.[0];
    },
    async callOrderPriceApiInitial() {
      await this.callOrderPriceApi()
        .catch((error) => this.showOutsideRiskModalErrorHandler(error));
    },
    async callGetUserInfoApi() {
      const res = await this.$webAppAPI.getBuyInfoOneScreen();
      res.data.order.price = res.data.order.price ?? 0;
      LogService.log('agency_id in user info api', res.data.order.agency_id !== undefined);
      this.updateAgencyId(res.data.settings?.agency_setting_id);

      if (res.data.order.agency_id !== undefined) {
        this.agency_id = res.data.order.agency_id;
      }
      res.data.order.risk_days = undefined;
      this.orderData = MergeHelper.mergeObjects(this.orderData, res.data.order);
      // as reduction and daily payout will be changed after optimizer call,
      // we set them initially to 0
      this.orderData.reduction = 0;
      this.orderData.daily_payout = 0;
      this.orderData.minHour = Math.min(...this.orderData.hours);
      this.orderData.maxHour = (Math.max(...this.orderData.hours) + 1);
      const dates = (this.orderData?.dates || []).map((d) => this.$moment(d, 'YYYY-MM-DD', true));
      if (dates.some((d) => !d.isValid())) {
        throw new Error('some of order API response dates is invalid!');
      }
      this.orderData.startDate = this.$moment.min(dates).format('LL');
      this.orderData.endDate = this.$moment.max(dates).format('LL');
      this.orderData.startDateNum = this.$moment.min(dates).format('YYYY-MM-DD');
      this.orderData.endDateNum = this.$moment.max(dates).format('YYYY-MM-DD');
      LogService.log('this.orderData', this.orderData);
      this.userData = res.data.user;
      this.$store.commit('SET_USER_STATUS', this.userData.status.toString());
      LogService.log('this.userData', res.data.user);
      this.profileData = MergeHelper.mergeObjects(this.profileData, res.data.profile);
      LogService.log('this.profileData', this.profileData);

      this.insurerId = res.data.insurer.id;
      this.agentId = res.data.agents[0].id;

      this.searchCountries = res.data.settings.allowed_residence_countries;
      this.min_postpone = res.data.settings.min_postpone_less_29 + 1;
      this.promotionId = res.data.settings.promotion_id;
      LogService.log('searchCountries updated', this.searchCountries);

      this.partnerName = res.data.partner.name;
      this.partnerCode = res.data.partner.partner_code;
      this.setLayoutByPartnerCode(this.partnerCode);
    },
    updateAgencyId(agencySettingId) {
      if (agencySettingId) {
        this.agencySettingId = agencySettingId;
        if (this.agencySettingId === agencySettingIds['1_to_20_chars']) {
          this.agency_title = this.str.buyVacation.agency_title;
          this.agency_text = this.str.buyVacation.agency_text;
          this.agency_plh = this.str.buyVacation.agency_plh;
          this.error_agency = this.str.buyVacation.error_agency;
          this.agencyIdValidation = agency_id_1_to_20;
        }
        if (this.agencySettingId === agencySettingIds['1_to_6_chars']) {
          this.agency_title = this.str.buyVacation.agency_title_2;
          this.agency_text = this.str.buyVacation.agency_text_2;
          this.agency_plh = this.str.buyVacation.agency_plh_2;
          this.error_agency = this.str.buyVacation.error_agency_2;
          this.agencyIdValidation = agency_id_1_to_6;
        }
      }
    },
    stopLoadingAndRedirect(error) {
      if (error?.response?.data?.message === errorStrings.emailAlreadyExists) {
        this.showEmailOccupied();
      } else {
        this.redirectToVacationErrorHandler(error);
      }
    },
    showEmailOccupied() {
      LogService.log('updateUser, email already occupied error');
      this.emailTaken = true;
      this.$store.commit('RESET_LOADING');
    },
    redirectToVacationErrorHandler(error) {
      LogService.log('BuyVacationErrorHandler', error);
      this.$store.commit('RESET_LOADING');
      this.$router.push({ name: 'vacation', params: { ...this.$route.params } });
    },
    async callOptimalContractAPI() {
      LogService.log('before call optimal contract');
      const res = await this.$webAppAPI.getOptimalContract().catch(
        (error) => this.showOutsideRiskModalErrorHandler(error),
      );
      LogService.log('after call optimal contract', res);

      if (this.$route.query.daily_payout) {
        const dailyPayout = parseInt(this.$route.query.daily_payout, 10);
        if (this.$route.query.daily_payout
          && this.possibleDailyPayoutValues.includes(dailyPayout)) {
          res.data.daily_payout = dailyPayout;
        }
      }

      if (this.setConstantDailyPayout) {
        res.data.daily_payout = 50;
      }

      this.orderData.daily_payout = res.data.daily_payout;
      this.orderData.weather.severity = res.data.weather_severity;
      this.orderData.risk_days = res.data.risk_days;
      LogService.log('dates', this.orderData.dates);
      this.orderData.reduction = (this.orderData.dates.length - res.data.risk_days)
        * res.data.daily_payout;
      this.orderData.weather.threshold = res.data.threshold;
    },
    async callOrderBuyApi() {
      await this.$webAppAPI.postBuy();
    },
    async checkDataBeforeGoNext() {
      this.$v.$touch();
      LogService.log('main val object', this.$v);
      this.$refs.nameAndPhone.$v.$touch();
      this.$refs.nameAndPhone.$refs.firstNameLastName.$v.$touch();
      LogService.log('name and phone val object', this.$refs.nameAndPhone.$v);
      LogService.log('name and phone val object', this.$refs.nameAndPhone.$refs.firstNameLastName.$v);
      this.$refs.registrationAddress.$refs.places.$v.$touch();
      LogService.log('places val object', this.$refs.registrationAddress.$refs.places.$v);

      this.$refs.registrationAddress.$v.$touch();
      LogService.log('placestext val object', this.$refs.registrationAddress.$v);

      // perform validation for RegisterSection only if user not registered already
      if (this.showRegistrationForm) {
        this.$refs.RegisterSection.$v.$touch();
        LogService.log('register val object', this.$refs.RegisterSection.$v);
        if (this.$refs.RegisterSection.$v.$invalid) {
          LogService.log('validation RegisterSection not passed');
          throw new Error('validation error');
        }
      }

      if (this.$v.$invalid || this.$refs.nameAndPhone.$v.$invalid
        || this.$refs.nameAndPhone.$refs.firstNameLastName.$v.$invalid) {
        LogService.log('nameAndPhone invalid', this.$refs.nameAndPhone.$v.$invalid);
        LogService.log('firstNameLastName invalid',
          this.$refs.nameAndPhone.$refs.firstNameLastName.$v.$invalid);
        LogService.log('$v invalid', this.$v.$invalid);
        LogService.log('$v invalid', this.$v);
        throw new Error('validation error');
      }
      if (this.registrationInvalidStreet || this.$refs.registrationAddress.$v.$invalid) {
        LogService.log('validation registration street not passed', this.registrationInvalidStreet);
        LogService.log('placesText validation object', this.$refs.registrationAddress.$v.$invalid);
        throw new Error('validation error');
      }

      if (this.registrationInvalidCountry) {
        LogService.log('correct country check not passed', this.registrationInvalidCountry);
        throw new Error('validation error');
      }

      if (this.$refs.registrationAddress.$refs.places.$v.error) {
        LogService.log('places error not passed', this.$refs.registrationAddress.$refs.places.$v.error);
        throw new Error('validation error');
      }
    },
    async redirectFullCoupon() {
      let validationError = false;
      await this.checkDataBeforeGoNext().catch(() => { validationError = true; });
      if (validationError) {
        return;
      }
      // finalize buy process and show loading bar in the meantime
      const loadingId = nanoid();
      LogService.log('creating screen, loadingId', loadingId);
      this.$store.commit('SET_LOADING', loadingId);
      await this.updateUser();
      await this.$webAppAPI.postBuy().then(() => {
        this.$store.commit('DEL_LOADING', loadingId);
        this.$router.push({ name: 'success', params: { ...this.$route.params } });
      });
    },
    async redirectToStripe() {
      let validationError = false;
      await this.checkDataBeforeGoNext().catch(() => { validationError = true; });
      if (validationError) {
        return;
      }
      // finalize buy process and show loading bar in the meantime
      const loadingId = nanoid();
      LogService.log('creating screen, loadingId', loadingId);
      this.$store.commit('SET_LOADING', loadingId);
      await this.updateUser();
      const stripeUrl = await this.createStripeSession();
      LogService.log('stripeResponse', stripeUrl);
      this.$store.commit('DEL_LOADING', loadingId);
      LogService.log('push stripe page view event to data layer');
      window.dataLayer.push({
        event: 'Pageview',
        pagePath: stripeUrl,
        pageTitle: 'Stripe',
        originalLocation: `${document.location.protocol}//${document.location.hostname}\
          ${this.$route.fullPath}`,
      });
      if (!this.inIframe()) {
        LogService.log('not in iframe, normal redirect to stripe');
        window.location.assign(stripeUrl);
      } else {
        LogService.log('in iframe, open stripe in same window', stripeUrl);
        window.top.location.href = stripeUrl;
      }
    },
    returnToVacation() {
      this.$router.push({
        name: 'vacation',
        params: { ...this.$route.params },
        query: {
          start_date: this.orderData.startDateNum,
          end_date: this.orderData.endDateNum,
          address: this.orderData.location.address,
        },
      });
    },
    returnToVacationIframe() {
      this.$router.push({ name: 'vacation', params: { ...this.$route.params }, query: { iframe: '2' } });
    },
    returnToDerTourVacation() {
      this.$router.push({
        name: 'dertour-regenversicherung',
        params: { ...this.$route.params },
        query: {
          start_date: this.orderData.startDateNum,
          end_date: this.orderData.endDateNum,
        },
      });
    },
    async next() {
      if (!this.personalInfoVisible) {
        this.personalInfoVisible = true;
        await this.$nextTick();
        setTimeout(() => {
          this.updateFirstname(this.$refs.nameAndPhone.firstname);
          this.updateLastname(this.$refs.nameAndPhone.lastname);
          this.$refs.registrationAddress.updateInitialAddress(
            this.mapAddressProfileToBusiness(this.profileData.registration_address),
          );
          this.updatePhone(this.$refs.nameAndPhone.phone);
          this.updatePhoneCode(this.$refs.nameAndPhone.phonePrefix);
        }, 1000);
        this.updateOpenedPersonalData();
        return;
      }

      LogService.log('clicked on next button');
      if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        await this.redirectFullCoupon().catch(
          (err) => this.stopLoadingAndRedirect(err),
        );
      } else {
        this.redirectToStripe().catch(
          (err) => this.stopLoadingAndRedirect(err),
        );
      }
    },
    back() {
      if (this.inIframe()) {
        this.returnToVacationIframe();
      } else if (this.orderData.payment_type_id === PaymentTypeId.fullCoupon) {
        this.returnToDerTourVacation();
      } else {
        this.returnToVacation();
      }
    },
    insurerApiCall() {
      return this.$webAppAPI.postInsurerSelection(this.insurerId);
    },
    showOutsideRiskModalErrorHandler(error) {
      LogService.log('Outside Risk model error');
      if ([
        errorStrings.outsideRisk,
        errorStrings.toMuchDataMissing,
        errorStrings.noContractInRiskBoundaries,
        errorStrings.stationsAreToAreAway].includes(error.response?.data?.message)) {
        this.showOutsideRiskModal();
      } else {
        this.showPriceCalcErrorModal(true);
      }
      this.$store.commit('RESET_LOADING');
    },
    showOutsideRiskModal() {
      const message = this.str.buyVacation.error_risk_outside;
      const modal = {
        id: 'wet-buy-vacation-outside-risk-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-buy-vacation-outside-risk-modal-ok',
            title: this.str.commonApp.ok,
            event: ['close', this.back],
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    showPriceCalcErrorModal(redirectToVacationPage = false) {
      const message = this.str.buyVacation.error_price_calc;
      const okEvent = redirectToVacationPage ? ['close', this.back] : 'close';
      const modal = {
        id: 'wet-buy-vacation-price-calc-error-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-buy-vacation-outside-risk-modal-ok',
            title: this.str.commonApp.ok,
            event: okEvent,
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    showWrongCountryModal() {
      const message = this.errorRegistrationWrongCountryFormatted;
      const modal = {
        id: 'wet-buy-vacation-wrong-country-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-buy-vacation-wrong-country-modal',
            title: this.str.commonApp.ok,
            event: ['close'],
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    getStreetAndNumber() {
      if (this.orderData.location.house) {
        return `${this.orderData.location.street} ${this.orderData.location.house}`;
      }
      return this.orderData.location.street;
    },
  },
  mounted() {
    this.loadMapScript()
      .then(() => this.initMaps());
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$emit('update:nextTitle', '');
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
};
</script>

<style>

</style>
